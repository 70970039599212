export default {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 486
  },
  fonts: {
    body:
      'Avenir, "Avenir Next", -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700
  },
  lineHeights: {
    body: 1.6,
    heading: 1.25
  },
  layout: {
    wrapper: {
      padding: 3,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    content: {
      width: '100%',
      flex: '1 1 auto',
    },
    header: {
      display: 'flex',
      position: ['unset', 'fixed'],
      top: 0,
      left: 0,
      padding: [0, 3],
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    footer: {
      padding: 2,
      width: '100%',
    },
    container: {
      marginTop: [4],
      padding: [0, 4],
      maxWidth: '600px'
    }
  },
  links: {
    cursor: 'pointer',
    logo: {
      color: 'text',
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    primary: {
      color: 'primary',
      textDecoration: 'none',
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    subtle: {
      color: 'text',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    inherit: {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  images: {
    markHeader: {
      width: '100%',
      display: 'block',
      padding: 0,
      margin: 0,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      boxShadow: `inset 1px 1px 1px blue`
    },
    avatar: {
      bg: 'muted',
      '&:hover': {
        boxShadow: t => `0 2px 2px highlight`
      }
    },
    avatarMenu: {
      height: '24px',
      width: '24px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    formImage: {
      borderRadius: 4
    }
  },
  colors: {
    text: 'rgb(52, 52, 52)',
    textLight: 'rgb(108, 108, 108)',
    background: 'rgb(245, 245, 245)',
    primary: 'rgb(97, 21, 212)',
    secondary: '#c0c',
    highlight: 'rgb(107, 31, 222)',
    muted: 'rgb(221, 221, 229)',
    error: 'tomato',
  },
  buttons: {
    destructive: {
      fontFamily: 'body',
      fontSize: 1,
      color: 'white',
      bg: 'error',
      cursor: 'pointer'
    },
    primary: {
      fontFamily: 'body',
      fontSize: 1,
      color: 'background',
      bg: 'primary',
      cursor: 'pointer',
      '&:hover': {
        bg: 'highlight'
      },
      '&:disabled': {
        cursor: 'default',
        bg: 'primary',
        color: 'rgba(255, 255, 255, 0.5)',
        boxShadow: 'none'
      }
    },
    primaryOnWhite: {
      fontFamily: 'body',
      fontSize: 1,
      color: 'background',
      bg: 'primary',
      cursor: 'pointer',
      '&:hover': {
        bg: 'highlight'
      },
      '&:disabled': {
        opacity: 0.25,
        cursor: 'default',
        color: 'textLight',
        bg: 'primary',
        boxShadow: 'none'
      }
    },
    secondaryOnWhite: {
      fontFamily: 'body',
      bg: 'transparent',
      fontSize: 1,
      color: 'text',
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        bg: 'white'
      }
    },
    inWell: {
      width: '100%',
      fontWeight: 'bold',
      cursor: 'pointer',
      fontFamily: 'body',
      fontSize: 1,
      background: 'none',
      color: 'text',
      outline: 'none',
      '&:hover': {
        color: 'white',
        bg: 'highlight',
      },
      '&:disabled': {
        color: 'text',
        cursor: 'default',
        bg: 'transparent',
        boxShadow: 'none'
      }
    },
    subtle: {
      fontFamily: 'body',
      fontSize: 1,
      color: 'text',
      textDecoration: 'none',
      cursor: 'pointer',
      bg: 'muted',
      '&:hover': {
        color: 'white',
        bg: 'highlight',
        boxShadow: t => `0 2px 2px highlight`
      }
    },
    menuItem: {
      background: 'none',
      textAlign: 'left',
      color: 'text',
      fontSize: 1,
      borderRadius: 0,
      display: 'block',
      width: '100%',
      minWidth: '120px',
      paddingLeft: 3,
      paddingRight: 3,
      paddingTop: 2,
      paddingBottom: 2,
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        bg: 'rgba(230, 230, 230, 0.8)'
      },
      '&:active': {
        outline: 'none',
        bg: 'rgb(230, 230, 230)'
      },
      '&:focused': {
        outline: 'none'
      }
    },
    menuItemDestructive: {
      variant: 'buttons.menuItem',
      '&:hover': {
        color: 'white',
        bg: 'error'
      }
    },
    autoCompleteSuggestion: {
      fontSize: 1,
      cursor: 'pointer',
      display: 'inlineBlock',
      borderRadius: 6,
      padding: 1,
      paddingX: 2,
      marginBottom: 2,
      marginX: 2,
      color: 'text',
      background: 'rgb(240, 240, 240) !important'
    },
    autoCompleteSuggestionHightlighted: {
      variant: 'buttons.autoCompleteSuggestion',
      opacity: 1,
      color: 'white',
      background: 'text'
    }
  },
  menuWrapper: {
    display: 'inline',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  text: {
    error: {
      fontSize: 1,
      color: 'error'
    },
    success: {
      fontSize: 1,
      color: 'text'
    },
    subtle : {
      fontSize: 1,
      color: 'textLight'
    },
    topic: {
      fontSize: 12,
      fontWeight: '500',
      color: 'rgba(178, 21, 139, 1)',
      bg: 'rgba(178, 21, 139, 0.05)',
      display: 'inline',
      paddingX: 2,
      paddingY: 1,
      letterSpacing: '.025em',
      borderRadius: '6px',
    }
  },
  cards: {
    mark: {
      bg: 'rgb(252, 252, 252)',
      borderRadius: 6,
      boxShadow: t => `inset 0 1px white, 0 1px rgba(0, 0, 0, 0.075), 0 2px 2px rgba(0, 0, 0, 0.1), 0 5px 5px -3px rgba(0,0,0,.2)`
    },
    markEditing: {
      variant: 'cards.mark',
      bg: 'rgb(240, 240, 240)',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.05)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.75)'
    },
    markLoading: {
      variant: 'cards.mark',
      padding: 2,
      display: 'block'
    },
    newsletter: {
      borderRadius: 6,
    },
    newsletterEditing: {
      variant: 'cards.newsletter',
      padding: 2,
      paddingTop: 1,
      background: 'rgba(255, 255, 255, 0.5)',
    },
    menu: {
      bg: 'rgba(255, 255, 255, 0.75)',
      backdropFilter: 'saturate(0.5) blur(15px)',
      borderRadius: 6,
      paddingTop: 2,
      paddingBottom: 2,
      boxShadow: `0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)`
    },
    autoCompleteSuggestions: {
      bg: 'white',
      border: t => `1px solid ${t.colors.muted}`,
      borderTop: 'none',
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 6,
      paddingTop: 2,
      marginLeft: '4px',
      marginRight: '4px'
    }
  },
  alerts: {
    error: {
      bg: 'tomato'
    }
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'bold',
      fontFamily: 'body',
      marginLeft: 1
    },
    input: {
      borderColor: 'muted',
      backgroundColor: 'white',
      fontFamily: 'body',
      '&::placeholder' : {
        fontFamily: 'body',
        color: 'muted'
      },
      '&:focus': {
        borderColor: 'primary',
        boxShadow: t => `0 2px 2px muted`,
        outline: 'none'
      },
      '&:hover': {
        borderColor: 'highlight'
      }
    },
    select: {
      borderColor: 'gray',
      '&:focus': {
        borderColor: 'highlight',
        boxShadow: t => `0 0 0 2px highlight`,
        outline: 'none'
      }
    },
    textarea: {
      borderColor: 'muted',
      backgroundColor: 'white',
      fontFamily: 'body',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: t => `0 2px 2px muted`,
        outline: 'none'
      },
      '&::placeholder' : {
        fontFamily: 'body',
        color: 'muted'
      },
      '&:hover': {
        borderColor: 'highlight'
      }
    },
    inline: {
      variant: 'forms.textarea',
      boxSizing: 'border-box',
      padding: 3,
    },
    slider: {
      bg: 'muted'
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    blockquote: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      paddingLeft: 3,
      my: 2,
      mx: 0,
      borderLeft: t => '3px solid rgb(0, 0, 0, 0.1)'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      py: 2
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      cursor: 'pointer'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    },
    spinner: {
      color: 'white',
    },
  }
}
