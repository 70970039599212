import React from 'react'
import Router from 'next/router'
import { ThemeProvider, Styled } from 'theme-ui'
import { DefaultSeo } from 'next-seo'
import AuthenticatedUserProvider from '@/context/authContext'
import FeatureGroupProvider from '@/context/featureGroupContext'
import UserProfileProvider from '@/context/userProfileContext'
import HydratedUserProvider from '@/context/hydratedUserContext'
import theme from '../src/theme'
import SEO from '../next-seo.config'
import { logPageView } from '@/helpers/Events'

/* https://github.com/hajola/nextjs-firebase-authentication/blob/master/pages/api/signup.js */

export default function App({ Component, pageProps, err }) {
  const getLayout = Component.getLayout ||  (page => page)

  return (
    <ThemeProvider theme={theme}>
      <DefaultSeo {...SEO} />
      <Styled.root>
        <AuthenticatedUserProvider>
          <FeatureGroupProvider>
            <UserProfileProvider>
              <HydratedUserProvider>
                {getLayout(
                  <Component {...pageProps} err={err} />
                )}
              </HydratedUserProvider>
            </UserProfileProvider>
          </FeatureGroupProvider>
        </AuthenticatedUserProvider>
      </Styled.root>
    </ThemeProvider>
  )
}

// Track events as users navigate pages
Router.events.on('routeChangeComplete', url => logPageView(url))