import { createContext, useContext, useState, useEffect } from 'react'
import { useAuthenticatedUser } from '@/context/authContext'
import { useUserProfile } from '@/context/userProfileContext'
import { useFeatureGroup } from '@/context/featureGroupContext'
import { setUID as loggingSetUID, setIsBeta as loggingSetIsBeta } from '@/helpers/Logging'

export const HydratedUserContext = createContext()
HydratedUserContext.displayName = 'HydratedUserContextContext'

export default ({ children }) => {
  const { authIsLoading: authLoading, authenticatedUser } = useAuthenticatedUser()
  const { profile, loading: profileLoading, error: profileError } = useUserProfile()
  const { isLoading: featureGroupLoading, isBeta: isBetaFeatureGroup, isStaff: isStaffFeaureGroup } = useFeatureGroup()

  const [isLoading, setIsLoading] = useState(true)
  const [isBeta, setIsBeta] = useState(false)
  const [isStaff, setIsStaff] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [hydratedUser, setHydratedUser] = useState(null)
  const [isFullyHydrated, setIsFullyHydrated] = useState(false)

  useEffect(() => {
    if (authLoading || profileLoading || featureGroupLoading) {
      return
    }

    // If the user isn't authenticated, early exit here
    if (!authenticatedUser) {
      setIsAuthenticated(false)
      setHydratedUser(null)
      setIsFullyHydrated(false)
      setIsBeta(false)
      setIsStaff(false)
      setIsLoading(false)

      // For logging purposes
      loggingSetUID(null)
      loggingSetIsBeta(null)
      return
    }

    setIsAuthenticated(true)

    // User is missing a profile, they're not fully hydrated
    if (profile) {
      setHydratedUser({
        ...profile
      })
      setIsFullyHydrated(true)
    } else {
      setHydratedUser(null)
      setIsFullyHydrated(false)
    }

    setIsBeta(isBetaFeatureGroup)
    setIsStaff(isStaffFeaureGroup)
    setIsLoading(false)

    // For logging purposes
    loggingSetUID(profile && profile.uid)
    loggingSetIsBeta(isBetaFeatureGroup)
  }, [authLoading, authenticatedUser, profile, profileLoading, profileError, featureGroupLoading, isBetaFeatureGroup])

  return (
    <HydratedUserContext.Provider value={{ isLoading, isAuthenticated, isFullyHydrated, hydratedUser, isBeta, isStaff }}>
      {children}
    </HydratedUserContext.Provider>
  )
}

// Custom hook that shorhands the context
export const useHydratedUser = () => useContext(HydratedUserContext)