export default {
  title: 'Sharemark',
  description: 'Sharemark is a newsletter publishing platform.',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    site_name: 'Sharemark',
  },
  twitter: {
    handle: '@dustin',
    site: '@dustin',
    cardType: 'summary_large_image'
  }
}
